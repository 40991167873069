
@import 'variables';
body{
  .action-docks{
    .MuiTabs-scrollable{
      margin-bottom:  0 !important;
    }
    .action-dock-tab{
      margin-bottom: 10px;
    }
    .warning-info-text{
      font-size: 12px;
      display: block;
      background: #fff1b6;
      padding: 2px 5px;
      color: #a78701;
      border: 1px solid #a78701;
      border-radius: 4px;
    }
    .action-dock-switcher{
      background: #E3E3E3;
      display: inline-block;
      border-radius: 4px;
      span{
        cursor: pointer;
        padding: 5px 10px 3px 10px;
        display: inline-block;
        &.active{
          background: #E8EEFF;
        }
      }
    }
    .review-docks-bulk-ops{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: #dfe7eb;
      z-index: 11;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 0 5px;
      .dock-bulk-ops-list{
        float: right;
        margin-bottom: 0 !important;
        >li{
          margin-left: 10px;
        }
      }
      .mobile-menu{
        display: none;
      }
    }
    .dock-bulk-ops{
      .dock-bulk-ops-list{
        margin-bottom: 10px;
        float: right;
        >li{
          margin-left: 10px;
        }
        .action-dock-switcher{
          background: #E3E3E3;
          display: inline-block;
          border-radius: 4px;
          span{
            cursor: pointer;
            padding: 5px 10px 3px 10px;
            display: inline-block;
            &.active{
              background: #E8EEFF;
            }
          }
        }
      }
      .warning-info-text{
        font-size: 12px;
        display: block;
        background: #fff1b6;
        padding: 2px 5px;
        color: #a78701;
        border: 1px solid #a78701;
        border-radius: 4px;
      }
    }
    table{
      thead{
        th{
          position: sticky !important;
          top: 0 !important;
        }
      }
      tbody{
        tr{
          .file-info{
            img{
              margin-right: 10px;
            }
            h5{
              @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
            }
            p{
              @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: #000000);
            }
          }
          .asset-info{
            h5{
              @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
            }
            p{
              @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: #000000);
            }
          }
          .actions-cell{
            ul{
              li{
                &:last-child{
                  margin-right: auto;
                }
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .ocr-analytic-blk{
      .ocr-count-card{
        padding: 15px;
        margin-bottom: 20px;
        p{
          @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #636363, $margin: 0 0 6px 0);
        }
        h4{
          @include elementStyles($size: 24px, $fontFamily: $ibmplexMedium, $color: #000000);
        }
      }
    }
  }
  .pagination-alignment{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
  .pagination-custom-field{
    width: 70px;
    margin-right: 8px;
    .MuiOutlinedInput-input{
      padding:5px 0px 8px 4px;
    }  
  }
  .go-buttons{
   background-color:#f7f1f1;
   height: 27px;
   width: 51px;
   border: 1px solid #b4adad;
   border-radius: 3px;
   cursor: pointer;
  }
  
}
@media only screen and (max-width: 1395px){
body {
.action-docks{
   table {
     thead {
       th {
         width:120px;
       }
     }
   }
  }
 }
}
@media only screen and (max-width: 600px){
  body{
    .action-docks{
      .action-docs-list-mobile-view{
        height: calc(100vh - 260px)!important;
      }
      .MuiTabs-scrollButtonsDesktop{
        display: block!important;
        position: relative;
        top: 14px;
      }
      .hidden-mobile{
        display: none;
      }
      .action-dock-switcher{
        display: none;
        position: relative;
        left: 12px;
        svg{
          font-size: 18px!important;
        }
        img{
          width: 18px;
        }
      }
      .action-dock-tab{
        .MuiTab-root{
          font-size: 13px;
        }
      }
      .dock-bulk-ops{
        margin-bottom: 15px;
        .MuiTypography-body1{
          font-size: 14px;
        }
        .dock-bulk-ops-list{
          margin-bottom: auto;
          li{
            margin-left: 8px!important;
            &:first-child{
              margin-left: auto;
            }
            .MuiButton-text{
              padding-right: 0px;
            }
            button{
              span{
                font-size: 12px;
              }
              svg{
                font-size: 20px;
              }
            }
            .action-dock-switcher{
              svg{
                font-size: 20px;
              }
              .img{
                width: 20px;
              }
            }
          }
        }
      }
      .review-docks-bulk-ops{
        .dock-bulk-ops-list{
          display: none;
        }
        .mobile-menu{
          display: block;
          text-align: right;
        }
      }
      table {
        thead{
          tr{
            white-space: nowrap;
            th{
              padding: 10px;
            }
          }
        }
        tbody{
          tr{
            white-space: nowrap;
            td{
              padding: 10px;
              ul{
                li{
                  img{
                    width: 18px;
                  }
                  svg{
                    font-size: 22px;
                  }
                }
              }
              svg{
                font-size: 20px;
              }
              &.actions-cell{
                .cta-mobile{
                  li{
                    &:first-child{
                      margin-bottom: 5px;
                    }
                    button{
                      .MuiButton-label{
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
            .file-info{
              h5{
                font-size: 12px;
              }
            }
          }
        }
      }
      .ocr-analytic-blk{
        .ocr-count-card {
          h4{
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 601px) and (max-width: 767.98px) {
  body {
    .action-docks{
      .review-docks-bulk-ops{
        .dock-bulk-ops-list{
          display: none;
        }
        .mobile-menu{
          display: block;
          text-align: right;
        }
      }
      .action-dock-tab{
        .MuiTab-root{
          font-size: 13px;
        }
      }
      .dock-bulk-ops{
        margin-bottom: 15px;
        ul{
          li{margin-left: 8px;
            &:first-child{
              margin-left: auto;
            }
            .MuiButton-text{
              padding-right: 0px;
            }
          }
        }
      }
      table {
        thead{
          tr{
            white-space: nowrap;
            th{
              padding: 10px;
            }
          }
        }
        tbody{
          tr{
            white-space: nowrap;
            td{
              padding: 10px;
              ul{
                li{
                  img{
                    width: 18px;
                  }
                  svg{
                    font-size: 22px;
                  }
                }
              }
            }
            .file-info{
              h5{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
  body{
    .action-docks{
      .action-dock-tab{
        .MuiTab-root{
          font-size: 13px;
        }
      }
      .dock-bulk-ops{
        margin-bottom: 15px;
        ul{
          li{margin-left: 8px;
            &:first-child{
              margin-left: auto;
            }
            .MuiButton-text{
              padding-right: 0px;
            }
          }
        }
      }
      table {
        thead{
          tr{
            white-space: nowrap;
            th{
              padding: 10px;
            }
          }
        }
        tbody{
          tr{
            white-space: nowrap;
            td{
              padding: 10px;
              ul{
                li{
                  img{
                    width: 18px;
                  }
                  svg{
                    font-size: 22px;
                  }
                }
              }
            }
            .file-info{
              h5{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

}

